import Navbar from "../../components/Navbar.js"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import GenericText from "../../components/ProjectPageComponents/GenericText.js"
import DoubleImages from "../../components/ProjectPageComponents/DoubleImages.js"


function Efundraiser() {
  return (
    <>
    <Navbar/>
    <div className="spacerM mnone"></div>
    <div style={{height:'32px',width:'100%'}}></div>

    <Overview
        status="Freelance Projects"
        title="Efundraiser"
        desc="Scalable fundraiser platform, with serverless architechture and reat time payment integrations"
        copyright="@Americloud Solutions 2024"
        links={["Live|https://donationkiosk.net/"]}
        image="kiosk2.png"
        style={{background: 'linear-gradient(90deg, #1DB48A 0%, #4CEA62 100%)', borderRadius: '12px'}}
    />

    <div className="spacerM"></div>

    <Probsol
        problem="How can we scale an existing project which is easy to integration anhy payment service and costs less to deploy and manage?"
        solution="Create a SAAS platform, with only one instance, with a serverless architechture, add dynamic payment modules, with RBAC architechture."
        tools="Dart, Typescript, Python, Node.js, Flutter, Google OCR, Firebase, Figma"
        team="@Americloud"
        duration="6m"
        optionname="Topic"
        optioncontent="SaaS"
    />

    <div className="spacerM"></div>

    <GenericText
        title="What it does"
        content={
            <>
            <p>Show all the causes for which your organization is accepting donations. Easily keep track of how much has been donated for each category. Allow donors to schedule recurring payments to help your organization meet your monthly funding goals.No need to wait for the office to be open. Donors can donate via the kiosk anytime they drop by.</p>
            <div className="row">
            <div style={{height:'24px',width:'100%'}}></div>
            <div className="col-md nopad">
                <p className="titlewidth"><b>Classes</b></p>
                <p className="titlewidth">Let your users look at what classes you have to offer. They can see what, where, and when the class takes place before registering.</p>
            </div>
            <div className="col-md nopad">
                <p className="titlewidth"><b>Ticket Registration</b></p>
                <p className="titlewidth">Registrants can quickly select the ticket type and quantity before proceeding to enter their contact info. You can optionally add an address and note field.</p>
            </div>
            </div>
            </>
        }
    />

    <div className="spacerM"></div>

    <DoubleImages
        title="Feature Pictures"
        captions={["Interface for web portal",
        "Real time dashboard data, with options to filter and download reports"]}
        images={["Efundraiser/interface.png","Efundraiser/dashboard.png"]}
    />

    <DoubleImages
        title=""
        captions={["Fully independent transactions progressions",
        "5 different customizable product types"]}
        images={["Efundraiser/transactions.png","Efundraiser/products.png"]}
    />
     <DoubleImages
        title=""
        captions={["Interface for kiosk portal",
        "interface for kiosk portal"]}
        images={["Efundraiser/kiosk3.png","Efundraiser/kiosk4.png"]}
    />

    <div className="spacerM"></div>


    <GenericText
        title="Reflection"
        content={
            <>
            <p>I'm really proid how this project turned out to be and on production being used by customers. I thank @Americloud Solutions for giving me the opportunity to develop this platform, I could have done better in terms of scaling this internationally. </p>
            <div style={{height:'24px',width:'100%'}}></div>
            <p>In the technical side I was able to learn lots about <b>serverless computing</b>, <b>REST API's</b>, and <b>mobile app design</b> 
            I was able to get hands-on experience on backend architechture and how to scale databases to large numbers from scratch.
            </p>
            </>
        }
    />

    <div className="spacerL"></div>
    <Bottom/>
    </>
  );
}

export default Efundraiser;
