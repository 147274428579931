import React, { useState } from 'react';
import Navbar from "../components/Navbar";
import Bottom from "../components/Bottom.js";
import { Link, useNavigate } from 'react-router-dom';

function Projects() {
  const [filter, setFilter] = useState('all');
  const router = useNavigate()
  const projects = [
    {
      id: 1,
      title: "Expertia",
      asset: "Expertia",
      category: "Contract",
      description: "All in one platform for AI-powered screening and assessments.",
      tech: ["JavaScript", "Python", "Machine Learning", "AI"],
      highlight: "Built it from scratch 0 to 1, First engineer in the team",
      image: 'logo.png',
      link: ""
    },
    {
      id: 2,
      title: "Perpetually",
      asset: "Perpetually",
      category: "Hackathon",
      description: "A solution for self-custody wallet owners to guard against accidental death by conditionally sending their crypto to beneficiaries",
      tech: ["Web3", "Blockchain", "Smart Contracts"],
      highlight: "Hackathon winner, web3",
      image: 'interface3.png',
      link: ""
    },
    {
      id: 3,
      title: "eFundraiser",
      asset: "eFundraiser",
      category: "Freelance",
      description: "SAAS for managing donations, classes, subscription, reports etc.",
      tech: ["JavaScript", "Python", "SAAS"],
      highlight: "Freelance project, Built it from scratch 0 to 1",
      image: 'dashboard.png',
      link: ""
    },
    {
      id: 4,
      title: "DarkForestDesigns",
      asset: "DarkForestDesigns",
      category: "Personal",
      description: "eCommerce Application",
      tech: ["JavaScript", "React", "Node.js"],
      highlight: "Personal project, 2023",
      image: 'interface.png',
      link: ""
    },
    {
      id: 5,
      title: "OfficeManagement",
      asset: "OfficeManagement",
      category: "Personal",
      description: "An office management application with real time employee tracking, attendance, tasks, and meetings",
      tech: ["JavaScript", "Real-time", "Database"],
      highlight: "Personal Project, 2020",
      image: 'interface.png',
      link: ""
    },
    {
      id: 5,
      title: "Non-Profit Website",
      asset: "Rahbarfoundation",
      category: "Personal",
      description: "A non-profit website made for donations a fundraising platform",
      tech: ["Payments", "Admin Dashboard", "CRM"],
      highlight: "Non-profit Project, 2020",
      image: 'logo-rahbar.png',
      link: "https://www.rahbarfoundation.org/"
    }
  ];

  const filteredProjects = filter === 'all' 
    ? projects 
    : projects.filter(project => project.category.toLowerCase() === filter);

  return (
    <div className="projects-page">
      <Navbar location='Projects' />
      
      <div className="projects-container">
        <section className="projects-hero">
          <h1 className="gradient-text">Featured Projects</h1>
          <p className="projects-subtitle">A collection of my work in software development and design</p>
        </section>

        <section className="projects-filter">
          <div className="filter-buttons">
            <button 
              className={`filter-btn ${filter === 'all' ? 'active' : ''}`}
              onClick={() => setFilter('all')}
            >
              All
            </button>
            <button 
              className={`filter-btn ${filter === 'contract' ? 'active' : ''}`}
              onClick={() => setFilter('contract')}
            >
              Contract
            </button>
            <button 
              className={`filter-btn ${filter === 'hackathon' ? 'active' : ''}`}
              onClick={() => setFilter('hackathon')}
            >
              Hackathon
            </button>
            <button 
              className={`filter-btn ${filter === 'personal' ? 'active' : ''}`}
              onClick={() => setFilter('personal')}
            >
              Personal
            </button>
          </div>
        </section>

        <section className="projects-grid">
          {filteredProjects.map((project) => (
            <div className="project-card" key={project.id}>
              <div className="project-image">
                <img src={`/assets/ProjectAssets/${project.asset}/${project.image}`} alt={project.title} />
                <div className="project-overlay">
                  {
                    project.link ? 
                    <a href={project.link} className="view-project">View Project</a>
                    :
                    <div onClick={() => router(project.title.toLowerCase().replace(/\s/g, ''))} className="view-project">View Project</div>
                  }
                </div>
              </div>
              <div className="project-content">
                <div className="project-header">
                  <h3>{project.title}</h3>
                  <span className="project-category">{project.category}</span>
                </div>
                <p className="project-description">{project.description}</p>
                <div className="project-highlight">
                  <span className="highlight-icon">✨</span>
                  {project.highlight}
                </div>
                <div className="project-tech">
                  {project.tech.map((tech, index) => (
                    <span key={index} className="tech-tag">{tech}</span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </section>

        <section className="connect-banner">
          <h2>Let's Build Something Amazing Together</h2>
          <p>Open to new opportunities and collaborations</p>
          <div className="banner-links">
            <a href="https://github.com/ahmeraftabpatel" target="_blank" rel="noopener noreferrer" className="banner-link">
              View GitHub
            </a>
            <a href="https://linkedin.com/in/ahmeraftab" target="_blank" rel="noopener noreferrer" className="banner-link">
              Connect on LinkedIn
            </a>
          </div>
        </section>
      </div>

      <Bottom />
    </div>
  );
}

export default Projects;
