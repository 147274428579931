import Navbar from "../../components/Navbar.js"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import DoubleImages from "../../components/ProjectPageComponents/DoubleImages.js"
import GenericText from "../../components/ProjectPageComponents/GenericText.js"

function OpenWeather() {
    return (
        <>
            <Navbar />
            <div className="spacerM mnone"></div>
            <div style={{ height: '32px', width: '100%' }}></div>

            <Overview
                status="Personal Project"
                title="OpenWeather"
                desc="Real-Time Weather Visualization of San Antonio using Google App Engine and OpenWeatherMap API"
                copyright=""
                links={["GitHub|https://github.com/AhmerAftabPatel/RealTime_OpenWeather_Api"]}
                image="interface.png"
                style={{ backgroundColor: 'black', borderRadius: '12px' }}
            />

            <div className="spacerM"></div>

            <Probsol
                problem="How can I give the real time updates and heavy weather visualization of a place, with full weather information and heavy loads?"
                solution="The Real-Time Weather Visualization of San Antonio project focuses on developing a web application that provides users with up-to-date weather information for different zip codes within the city of San Antonio. By leveraging the capabilities of Google App Engine and integrating with the OpenWeatherMap API, the application offers an interactive map-based interface that displays current weather conditions, temperature, humidity, and wind speed for each zip code."
                tools="Google App Engine"
                team="Team project"
                duration="On Hiatus"
                optionname="Topic"
                optioncontent="Cloud computing"
            />

            <div className="spacerM"></div>

            <GenericText
                title="What it does"
                content={
                    <>
                        <p>The project focuses specifically on the city of San Antonio and provides weather information for various zip codes within the city boundaries. The application relies on the OpenWeatherMap API for retrieving weather data, and the accuracy and availability of the data are subject to the limitations of the API service. The application does not provide weather forecasts or historical data analysis</p>
                    </>
                }
            />

            <div className="spacerM"></div>

            <DoubleImages
                title="Visuals"
                captions={["Interface of the application",
                    "This image contains an explaination of how google app engine handles load"]}
                images={["OpenWeather/interface.png", "OpenWeather/explain.png"]}
            />


            <DoubleImages
                title="Technical images"
                captions={["Paper for the project",
                    "Sample zipcodes for san antonio"]}
                images={["OpenWeather/explain2.png", "OpenWeather/interface2.png"]}
            />

            <div className="spacerM"></div>

            <GenericText
                title="How It Works"
                content={
                    <p>
                       The OpenWeatherMap API is integrated into the application to retrieve real-time weather data for different zip codes in San Antonio. The API provides current weather information, including temperature, humidity, wind speed, and weather conditions. The application sends HTTP requests to the API endpoints, passing the required parameters such as zip code and API key, and receives the weather data in JSON format
                       Check out this quick <b><a href="https://utsa.hosted.panopto.com/Panopto/Pages/Viewer.aspx?id=40db7e3d-f001-4c8e-87bf-b16400385fc9">Presentation</a></b></p>
                }
            />

            <div className="spacerL"></div>
            <Bottom />
        </>
    );
}

export default OpenWeather;
