import Projects from "./pages/Projects"
import Experience from "./pages/Experience"
import About from "./pages/About"

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom"
import Perpetually from "./pages/ProjectFolder/Perpetually.js"
import OpenWeather from "./pages/ProjectFolder/Openweather.js"
import Expertia from "./pages/ProjectFolder/Expertia.js"
import Efundraiser from "./pages/ProjectFolder/Efundraiser.js"
import TextBasedGame from "./pages/ProjectFolder/Textbasesrpggame.js"
import VideoCallingApp from "./pages/ProjectFolder/VideoCallingApp.js"
import Officemanagement from "./pages/ProjectFolder/Officemanagement.js"
import DarkForestDesigns from "./pages/ProjectFolder/DarkForestDesigns.js"

function App() {
  return (
    <Router>
      {/* <TopScroll> */}
        <Routes>
          {/* main pages */}
          <Route exact path="/" element={<Projects />} />

          <Route exact path="/experience" element={<Experience/>} />

          <Route exact path="/about" element={<About />} />

          {/* project pages */}
          <Route exact path="/perpetually" element={<Perpetually />} />
          <Route exact path="/openweather" element={<OpenWeather />} />
          <Route exact path="/expertia" element={<Expertia />} />
          <Route exact path="/efundraiser" element={<Efundraiser />} />
          <Route exact path="/textbasedrpggame" element={<TextBasedGame />} />
          <Route exact path="/videocallingapp" element={<VideoCallingApp />} />
          <Route exact path="/officemanagement" element={<Officemanagement />} />
          <Route exact path="/darkforestdesigns" element={<DarkForestDesigns />} />
        </Routes>
      {/* </TopScroll> */}
    </Router>
  );
}

export default App;
