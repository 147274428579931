import Navbar from "../../components/Navbar.js"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import UnderConstruction from "../../components/ProjectPageComponents/UnderConstruction.js"


function VideoCallingApp() {
  return (
    <>
      <Navbar />
      <div className="spacerM mnone"></div>
      <div style={{ height: '32px', width: '100%' }}></div>

      <Overview
        status="Personal Project"
        title="Video Calling App"
        desc="Sample application for how Web RTC works and demonstrate how realtime message passing happens"
        copyright=""
        links={["Live|https://videochatahmer.netlify.app", "Github|https://github.com/AhmerAftabPatel/WebRTC"]}
        image="interface2.png"
        style={{ backgroundColor: '#2A363B', borderRadius: '12px' }}
      />

      <div className="spacerM"></div>

      <Probsol
        problem="I want to create a video calling application which reatime shows local and virtual data passing using webRTC"
        solution="WebRTC (Web Real-Time Communication) enables peer-to-peer audio, video, and data sharing between browsers without requiring plugins. Optionally implement RTCDataChannel for sending non-media data between peers. Our video calling application utilizes React for its frontend interface and leverages WebRTC for real-time communication capabilities. Users can initiate video calls directly from their web browsers, seamlessly accessing their camera and microphone through getUserMedia. A signaling server, powered by socket.io, facilitates peer discovery and session management, ensuring secure peer-to-peer connections via RTCPeerConnection. The application supports intuitive UI controls for managing calls, including mute options and call termination. With a focus on scalability and cross-browser compatibility, our solution enables reliable video communication experiences across diverse devices and operating systems, all within a secure HTTPS environment."
        tools="Reactjs, Typescript, webRTC, Firebase"
        team="Ahmer"
        duration="24h"
        optionname="Topic"
        optioncontent="webRTC"
      />

      <div className="spacerL"></div>

      {/* <UnderConstruction/> */}

      <div className="spacerL"></div>
      <Bottom />
    </>
  );
}

export default VideoCallingApp;
