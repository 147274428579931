import Navbar from "../../components/Navbar"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import UnderConstruction from "../../components/ProjectPageComponents/UnderConstruction.js"


function TextBasedGame() {
  return (
    <>
    <Navbar/>
    <div className="spacerM mnone"></div>
    <div style={{height:'32px',width:'100%'}}></div>

    <Overview
        status="Hackathon Winner"
        title="Text Based Rpg Game"
        desc="Connect farmers with excess produce to local consumers to reduce food waste due to COVID-19"
        copyright=""
        links={["Resource|https://drive.google.com/drive/u/0/folders/1XxODsza5P7JpQhGaeSDSKNwskquAP7Jl"]}
        image="interface.png"
        style={{backgroundColor: '#2A363B', borderRadius: '12px'}}
    />

    <div className="spacerM"></div>

    <Probsol
        problem="I want to create a game where we can use text based commands to make progress and the story should change based on my input?"
        solution="The project involves creating a text-based RPG game using Unity 3D and C#. Players navigate through a fantasy world depicted through text descriptions and make decisions that influence the story and their character's progression. Utilizing Unity's UI Text components, C# scripts manage gameplay mechanics such as inventory management, dialogue trees, and scene transitions, while visual and audio elements enhance the immersive experience. The game aims to combine traditional RPG elements with modern game development techniques to deliver an engaging narrative-driven adventure playable on multiple platforms."
        tools="Unity3D, Tensorflow, Figma, C#, Typescript"
        team="Rishikesh, Kanishaka and Ahmer"
        duration="1w"
        optionname="Topic"
        optioncontent="Game Design"
    />

    <div className="spacerL"></div>

    {/* <UnderConstruction/> */}

    <div className="spacerL"></div>
    <Bottom/>
    </>
  );
}

export default TextBasedGame;
