import React from 'react';
import Navbar from "../components/Navbar";
import Bottom from "../components/Bottom.js";

function About() {
  const skills = [
    { name: "Product Management", icon: "💼" },
    { name: "Blockchain", icon: "⛓️" },
    { name: "DevOps", icon: "🔄" },
    { name: "UX Design", icon: "🎨" },
    { name: "React", icon: "⚛️" },
    { name: "Node.js", icon: "🟢" }
  ];

  const interests = [
    { name: "Hackathons", description: "Love building innovative solutions under pressure" },
    { name: "Food Explorer", description: "Always trying new cuisines and restaurants" },
    { name: "Gaming", description: "Passionate about gaming and game development" },
    { name: "Manga", description: "Avid reader of manga and Japanese culture enthusiast" }
  ];

  return (
    <div className="about-page">
      <Navbar location='About' />
      
      <div className="about-container">
        <section className="hero-section">
          <div className="hero-content">
            <div className="hero-text">
              <h1 className="gradient-text">Hello, I'm Ahmer</h1>
              <p className="hero-subtitle">A Software Engineer passionate about building great products</p>
            </div>
            <div className="hero-image">
              <img src='/assets/me.png' alt="Ahmer" className="profile-image" />
              <div className="image-backdrop"></div>
            </div>
          </div>
        </section>

        <section className="about-section">
          <div className="about-grid">
            <div className="about-text">
              <h2>About Me</h2>
              <p>
                Currently pursuing a masters in <span className="highlight">Computer Science with a Specialization in Software Engineering</span> at 
                the <a href="https://www.utsa.edu/" className="link-highlight" target="_blank" rel="noopener noreferrer">University of Texas</a>.
              </p>
              <p>
                As a developer from Texas, I blend technical expertise with creative problem-solving. 
                My journey in tech is driven by a passion for creating impactful solutions and continuous learning.
              </p>
            </div>
          </div>
        </section>

        <section className="skills-section">
          <h2>What I Do</h2>
          <div className="skills-grid">
            {skills.map((skill, index) => (
              <div className="skill-card" key={index}>
                <span className="skill-icon">{skill.icon}</span>
                <span className="skill-name">{skill.name}</span>
              </div>
            ))}
          </div>
        </section>

        <section className="interests-section">
          <h2>Beyond Coding</h2>
          <div className="interests-grid">
            {interests.map((interest, index) => (
              <div className="interest-card" key={index}>
                <h3>{interest.name}</h3>
                <p>{interest.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="connect-section">
          <h2>Let's Connect</h2>
          <div className="connect-content">
            <p>
              Whether you want to discuss tech, share project ideas, or just chat about manga,
              I'm always open to connecting with fellow developers and enthusiasts.
            </p>
            <div className="social-links">
              <a href="https://github.com/ahmeraftabpatel" target="_blank" rel="noopener noreferrer" className="social-link">
                GitHub
              </a>
              <a href="https://linkedin.com/in/ahmeraftab" target="_blank" rel="noopener noreferrer" className="social-link">
                LinkedIn
              </a>
            </div>
          </div>
        </section>
      </div>

      <Bottom />
    </div>
  );
}

export default About;
