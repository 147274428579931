import Navbar from "../../components/Navbar.js"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import MultipleImages from "../../components/ProjectPageComponents/MultipleImages.js"
import GenericText from "../../components/ProjectPageComponents/GenericText.js"

function Expertia() {
    return (
        <>
            <Navbar />
            <div className="spacerM mnone"></div>
            <div style={{ height: '32px', width: '100%' }}></div>

            <Overview
                status="Contract Project"
                title="Expertia"
                desc="Expertia aims to simplify the process of skill discovery and their employment through super accurate algorithms and hyper profiling of professionals."
                copyright="© 2024, Expertia AI. All rights reserved."
                links={["Live Link|https://expertia.ai/", "Linkedin|https://www.linkedin.com/company/expertia-ai/"]}
                image="interface4.png"
                style={{ backgroundColor: '#D6BDFF', borderRadius: '12px' }}
            />

            <div className="spacerM"></div>

            <Probsol
                problem="How can a platform be beneficial for both companies and candidates to land or secure a job with 1/10th of the costs?"
                solution="An application that allows you to remotely view and edit your candidates information, rank them based on JD and provides end to end hiring solution."
                tools="Python, Tensorflow. Pytorch, Figma, and Adobe Illustrator"
                team="Contract project"
                duration="Ongoing"
                optionname="Topic"
                optioncontent="Human Resource and Artifitial interlligence"
            />

            <div className="spacerM"></div>

            <MultipleImages
                title="App Preview"
                captions={[]}
                images={["Expertia/interface2.png", "Expertia/interface3.png"]}
            />

            <div className="spacerM"></div>

            <GenericText
                title="Features Summary"
                content={
                    <>
                        <div className="row">
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>Candidate Sourcing</b></p>
                                <p className="titlewidth">Build a solid hiring pipeline by posting & broadcasting your job to 100+ job platforms in a single click.</p>
                            </div>
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>AI-Powered Screening</b></p>
                                <p className="titlewidth">Let our advanced AI algorithms find the most relevant candidates, saving you time and effort.</p>
                            </div>
                        </div>
                        <div style={{ height: '24px', width: '100%' }}></div>
                        <div className="row">
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>Activate Talent Pool</b></p>
                                <p className="titlewidth">Let our AI fetch the best candidates from your talent pool, or use filters to find the best candidates.</p>
                            </div>
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>Customised Assessments</b></p>
                                <p className="titlewidth">Conduct customised assessments on 100s of technical and non-technical skills in a proctored environment and receive comprehensive skill reports.</p>
                            </div>
                        </div>
                        <div style={{ height: '24px', width: '100%' }}></div>
                        {/* <div className="row">
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>Risks</b></p>
                                <p className="titlewidth">Legal responsibility from app misuse and time availability to code and test the app</p>
                            </div>
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>Initial goal</b></p>
                                <p className="titlewidth">Achieve over 100k downloads on the app store within a one year period</p>
                            </div>
                        </div> */}
                    </>
                }
            />

            <div className="spacerM"></div>

            <MultipleImages
                title="Few other images"
                captions={["Looked cool but too many elements weren't intuitive such as the weekly reliability graphic",
                    "Expertia is backed by investors and is being used by 100+ companies",
                    "You can integrate expertia with your own career page"]}
                images={["Expertia/interface4.png", "Expertia/interface5.png", "Expertia/interface6.png"]}
            />


            <div className="spacerM"></div>

            <GenericText
                title="Vision of the product"
                content={
                    <p>Expertia believe that despite the growth in HR Tech
                        over the last 2 decades, skill identification, evaluation,
                        upgradation and employment is still a complex and broken process.

                        To make this process simple and accessible requires understanding of what skills mean, how they progress,
                        the ability to identify hidden skills, their dependencies, adjacencies and evaluation. </p>
                }
            />
            <div className="spacerM"></div>
            <GenericText
                title="Coding it up"
                content={
                    <p>As a founding engineer, developed an end to end recruitment platform which interacted with advance ML algorithms to source and rank candidates based on job description. Engineered and scaled the product with co-founders acquiring 60+ clients and $1.2M of funding in the first year of release.
                        Worked directly with the founders to scale the product nationwide & was<b> recognized by  google for startups in 2023</b>
                    </p>
                }
            />

            <div className="spacerL"></div>
            <Bottom />
        </>
    );
}

export default Expertia;
