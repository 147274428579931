import Navbar from "../../components/Navbar.js"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import GenericText from "../../components/ProjectPageComponents/GenericText.js"
import DoubleImages from "../../components/ProjectPageComponents/DoubleImages.js"


function Perpetually() {
    return (
        <>
            <Navbar />
            <div className="spacerM mnone"></div>
            <div style={{ height: '32px', width: '100%' }}></div>

            <Overview
                status="Hackathon Winner"
                title="Perpetually"
                desc="A solution for self-custody wallet owners to guard against accidental death by conditionally sending their crypto to beneficiaries."
                copyright=""
                links={["Twitter|https://x.com/easya_app/status/1797420351334543836", "GitHub|https://github.com/ABusyHippie/PerpetuallyConsensusHack2024"]}
                image="main.png"
                style={{ background: 'linear-gradient(90deg, #1DB48A 0%, #4CEA62 100%)', borderRadius: '12px' }}
            />

            <div className="spacerM"></div>

            <Probsol
                problem="Self-custody wallet owners don’t have a good way to transfer their assets to loved ones in the event of their death. $100B+ could be lost if the key owners don’t prepare their succesion plans."
                solution="We built out a quick demo for passing along your digital assets to an heir using smart contracts (instead of writing down private keys that aren't secure and someone may not know how to use). Because BTC is the largest store of value in crypto we used Rootstock and got their 4th place bounty of $5,000"
                tools="Dart, Typescript, Python, Node.js, Flutter, Google OCR, Firebase, Figma"
                team=" Brian Meckler, Sung Wu, Bart Hofkin and Ahmer Patel"
                duration="24h"
                optionname="Topic"
                optioncontent="Blockchain"
            />

            <div className="spacerM"></div>

            <GenericText
                title="What it does"
                content={
                    <>
                        <p>Billions of dollar value is stored in non-custodial crypto accounts that cannot be easily transferred to family members and loved ones if the account owners pass away.  If you get hit by a truck tomorrow are you confident your children will acquire your crypto holdings?
                            Perpetually leverages Rootstock EVM-compatible smart contracts that are triggered through various conditions and events. The wallet owner uses Perpetually to configure the custom smart contract with their desired distribution details: when and how to trigger their distribution, what to distribute, in what proportions, etc</p>
                        <div className="row">
                            <div style={{ height: '24px', width: '100%' }}></div>
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>EVM-compatible smart contracts</b></p>
                                <p className="titlewidth">Perpetually leverages Rootstock EVM-compatible smart contracts that are triggered through various conditions and events. The wallet owner uses Perpetually to configure the custom smart contract with their desired distribution details: when and how to trigger their distribution, what to distribute, in what proportions, etc</p>
                            </div>
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>Distribute assets not keys</b></p>
                                <p className="titlewidth">Passphrases and private keys are inherently insecure to transmit. We want to leverage the security of the blockchain to actually transfer funds between accounts.</p>
                            </div>
                        </div>
                    </>
                }
            />

            <div className="spacerM"></div>

            <DoubleImages
                title="Feature Pictures"
                captions={["Meet the team of perpetually",
                    "Example of app running"]}
                images={["Perpetually/team.jpg", "Perpetually/interface2.png"]}
            />

            <DoubleImages
                title=""
                captions={["Adding the death trigger",
                    "Running the wallet app on web"]}
                images={["Perpetually/interface4.png", "Perpetually/interface3.png"]}
            />

            <div className="spacerM"></div>

            <GenericText
                title="Technical Details"
                content={
                    <>
                        <p>
                            The Perpetually blockchain was made using <b>Typescript</b> and <b>Node.js</b> and is a implementation of <a href="https://github.com/lhartikk/naivecoin" className="link" target="_blank"><span>Rootstock</span></a>.
                            The blockchain has testing tools and a CLI (command line interface) made with <b>Python</b>.
                            The majority of funds in the ecosystem are within Bitcoin which at the time of this writing has a market cap of $1.3T, so we wanted to build where the money was. EVM compatibility makes using Rootstock an easy choice for us since we all had familiarity building on Ethereum. In addition to this the healthy ecosystem of partners and RPC enabled calls made our lives even easier with out of the box components. In particular we are using thirdweb for most of our smart contract work -  our demo leverages the Split() functionality we used the <b>Thirdweb API</b> which we implemented using a <b>REST API</b>.
                            Also, a <b>Google Firebase database</b> to store non-sensitive information related to Perpetually users. The frontend was drafted and prototyped with <b>Figma</b> and implemented using the <b>React</b> framework for <b>Javascript</b>.
                        </p>
                    </>
                }
            />

            <div className="spacerM"></div>

            <GenericText
                title="Reflection"
                content={
                    <>
                        <p>I'm really proud of how this project turned out especially given the short amount of time we had to complete it.
                            Little did we know that we would win this hackathon and secure that winning prize. Couldn't have done it in such a short time without leveraging smart contracts from thirdweb who also provided lots of help with getting everything up and running, and Rootstock was incredibly patient and supportive while we built on their testne.
                        </p>
                        <div style={{ height: '24px', width: '100%' }}></div>
                        <p>In the technical side I was able to learn lots about <b>proof of stake blockchains</b>, <b>smart contracts</b>, <b>REST API's</b>, and <b> app design</b> in such a short time span and actually implement it!
                            I also got to get a better feel for working under a time crunch especially when it came <b>understanding how to prioritize features</b>, communicating with team members, and testing and debugging.
                            I hope that if we pick up this project again we can work on the security and also deploy a working mainnet that others can really use.
                        </p>
                    </>
                }
            />

            <div className="spacerL"></div>
            <Bottom />
        </>
    );
}

export default Perpetually;
