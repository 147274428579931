import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Navbar = ({ location }) => {
    const [scrolled, setScrolled] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`navbar-wrapper ${scrolled ? 'scrolled' : ''}`}>
            <div className="container-fluid mx-auto bound">
                <nav className="navbar navbar-expand-lg">
                    <button 
                        className={`navbar-toggler ${mobileMenuOpen ? 'open' : ''}`} 
                        type="button" 
                        data-toggle="collapse" 
                        data-target="#navbarNav" 
                        aria-controls="navbarNav" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                        <span className="toggler-icon"></span>
                        <span className="toggler-icon"></span>
                        <span className="toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${mobileMenuOpen ? 'show' : ''}`} id="navbarNav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link 
                                    className={`nav-link ${location === "Projects" ? 'active' : ''}`} 
                                    to="/"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="nav-number">01.</span>
                                    Projects
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link 
                                    className={`nav-link ${location === "Work" ? 'active' : ''}`} 
                                    to="/experience"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="nav-number">02.</span>
                                    Experience
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link 
                                    className={`nav-link ${location === "About" ? 'active' : ''}`} 
                                    to="/about"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="nav-number">03.</span>
                                    About
                                </Link>
                            </li>
                        </ul>
                        <div className="navbar-nav ml-auto">
                            <a 
                                href="https://github.com/ahmeraftabpatel" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="nav-social-link"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                                </svg>
                            </a>
                            <a 
                                href="https://linkedin.com/in/ahmeraftab" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="nav-social-link"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                    <rect x="2" y="9" width="4" height="12"></rect>
                                    <circle cx="4" cy="4" r="2"></circle>
                                </svg>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;
