import Navbar from "../components/Navbar"
import Workcard from "../components/MainPageComponents/Workcard.js"
import Bottom from "../components/Bottom.js"
import Awards from "../components/MainPageComponents/Awards.js"

function Projects() {
  return (
    <>
    <Navbar location='Work' />

    <div className="spacerM mnone"></div>
    <div className="monly" style={{height:'48px',width:'100%'}}></div>

    <div className="row justify-content-center">

        <div className="row">
          <Workcard
            title="Lab Assistant"
            time="Summer 2023"
            company="Parralel and Distrubited computing"
            description="Managing software/hardware projects of the lab at the University of Texas"
            tools="Laravel, PHP, Python"
            linkPath="https://www.utsa.edu/"
          />
          <Workcard
            title="Software Engineer Freelance"
            time="Winter 2023"
            company="Americloud Solutions"
            description="Lead development of a new centralized deployment UI, several automation pipelines, and standardized development processes"
            tools="Kubernetes,Ansible,Docker,Helm,GitLab,Nestjs"
            linkPath="https://www.americloud.net"
          />
        </div>
        <div style={{height:'0px',width:'100%'}}></div>

        <div className="row">
          <Workcard
            title="Graduate Teaching Assistant"
            time="Spring 2023"
            company="Database Systems"
            description="Managing assignements, grading, teaching students at the University of Texas"
            tools="MySQL, database systems, architechture, scaling"
            linkPath="https://www.utsa.edu/"
          />
          <Workcard
            title="Backend Developer"
            time="Spring 2023"
            company="Scoolar Lab"
            description="Contributed to an NSF project on micro-mobility sensing by developing a PHP/Laravel application. Which enables researchers to visualize and analyze incoming data for research"
            tools="Laravel, PHP, Mysql, html, tailwindcss"
            linkPath="https://scooterlab.utsa.edu/"
          />
        </div>
        <div style={{height:'0px',width:'100%'}}></div>

        <div className="row">
          <Workcard
            title="Software Engineer"
            time="June 2021 - Jan 2023"
            company="Expertia AI"
            description="Designed and developed end to end recruitment platform including overall architecture, project management, database and cloud."
            tools="Figma,Reactjs,Mongodb,socket.io"
            linkPath="https://expertia.ai"
          />
          <Workcard
            title="Software Engineer Intern"
            time="All of 2020"
            company="Pohu Labs"
            description="Contributed to using real time IOT device and data collection module of plants"
            tools="Python,Pytorch"
            linkPath="https://pohulabs.com/"
          />
        </div>
    </div>
    <div className="spacerM"></div>

    <div className="row mx-4 justify-content-center">
        <Awards
            awards="
            2024|
            Consensus Hackathon 2024 Winner-Top 4 overall-$5000 rBTC prize-1000+ participants,
            |2023|
            Awarded Competitive Scholarship-University of Texas-Computer Science department-$1000 over the semester,
            Competitive Scholarship-Awarded for academic excellence-Indian Student Association of San Antonio,
            |2022|
            StartUp Exposure-Founding Engineer-Built mvp which lead to $1.2m in funding"
        />
    </div>

    <div className="spacerL"></div>

    <Bottom />
    </>
  );
}

export default Projects;
