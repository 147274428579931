import Navbar from "../../components/Navbar.js"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import UnderConstruction from "../../components/ProjectPageComponents/UnderConstruction.js"
import GenericText from "../../components/ProjectPageComponents/GenericText.js"


function Officemanagement() {
  return (
    <>
      <Navbar />
      <div className="spacerM mnone"></div>
      <div style={{ height: '32px', width: '100%' }}></div>

      <Overview
        status="Personal Project"
        title="Office Management"
        desc="A office managemnet application with features like online attendance with gps, meetings assigner, task scheduler etc..."
        copyright=""
        links={["Demo|https://www.loom.com/share/eca54044627d433c93ab22eb9a2dc737?t=159&sid=99496748-bd6e-40be-8147-436350e74ca2"]}
        image="interface2.png"
        style={{ backgroundColor: '#2A363B', borderRadius: '12px' }}
      />

      <div className="spacerM"></div>

      <Probsol
        problem="I want to create an application where employees and admin can coordinate in an office setting easily and save time"
        solution="An application where uses can signup, as an admin, employee. Admin can assign tasks, creat meetings, sort attendance and employee can accep the task, change status, add images and vidos, add comments etc..."
        tools="Reactjs, Flutter, Firebase, ExpressJs"
        team="Ahmer and Omair"
        duration="3m"
        optionname="Topic"
        optioncontent="SaaS"
      />

      <div className="spacerL"></div>

      <GenericText
        title="Features Summary"
        content={
          <>
            <div className="row">
              <div className="col-md nopad">
                <p className="titlewidth"><b>Online Attendance</b></p>
                <p className="titlewidth">Automatic attendance tracker using GPS technology</p>
              </div>
              <div className="col-md nopad">
                <p className="titlewidth"><b>Meetings Scheduler</b></p>
                <p className="titlewidth">Schedule and get insights of meetings using open spurce APIs.</p>
              </div>
            </div>
            <div style={{ height: '24px', width: '100%' }}></div>
            <div className="row">
              <div className="col-md nopad">
                <p className="titlewidth"><b>Create Tasks</b></p>
                <p className="titlewidth">Create and assign tasks and track the life cycle of products.</p>
              </div>
            </div>
            <div style={{ height: '24px', width: '100%' }}></div>
            {/* <div className="row">
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>Risks</b></p>
                                <p className="titlewidth">Legal responsibility from app misuse and time availability to code and test the app</p>
                            </div>
                            <div className="col-md nopad">
                                <p className="titlewidth"><b>Initial goal</b></p>
                                <p className="titlewidth">Achieve over 100k downloads on the app store within a one year period</p>
                            </div>
                        </div> */}
          </>
        }
      />

      {/* <UnderConstruction/> */}

      <div className="spacerL"></div>
      <Bottom />
    </>
  );
}

export default Officemanagement;
